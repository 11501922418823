import "react-app-polyfill/ie11";
import "semantic-ui-css/semantic.min.css";
import "./index.css";
import ReactDOM from "react-dom";
import React, { useEffect, useState } from "react";
import { Provider } from "react-redux";
import { ConnectedRouter } from "react-router-redux";
import { createBrowserHistory } from "history";
import { unregister } from "./registerServiceWorker";
import { PersistGate } from 'redux-persist/integration/react'
import { QueryClient, QueryClientProvider } from 'react-query'
import { useGlobalFeaturesStore } from "store/GlobalFeaturesStore";

unregister();

const queryClient = new QueryClient();

// get global feature flag
const store = useGlobalFeaturesStore.getState();
store.getGlobalFeatureFlags()
  .then(() => {
    //Dynamically import App and Configure to prevent load of UserManager before GlobalFeature flag is fetched
    import("./store/configureStore").then(({ default: ConfigureStore }) => {
      import("./App").then(({ default: App }) => {
        const { store, persistor } = ConfigureStore(history, initialState);
        const baseUrl = document.getElementsByTagName("base")[0].getAttribute("href");
        const history = createBrowserHistory({ basename: baseUrl });
        const initialState = window.initialReduxState;
        const rootElment = document.getElementById("root");

        ReactDOM.render(
          <Provider store={store}>
            <ConnectedRouter history={history}>
              <PersistGate loading={null} persistor={persistor}>
                <QueryClientProvider client={queryClient}>
                  <App />
                </QueryClientProvider>
              </PersistGate>
            </ConnectedRouter>
          </Provider>,
          rootElment
        )
      })
    })
  })
