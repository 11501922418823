import create from "zustand";
import Axios from "axios";
const sessionGlobalFeatureFlagKey = "_session_global_flag_key";

export type GlobalFeatureFlags = {
  feMfaLogin: boolean;
};

interface GlobalFeatureState {
  globalFeatures: GlobalFeatureFlags | null;
  getGlobalFeatureFlags: () => Promise<void>;
}
export const useGlobalFeaturesStore = create<GlobalFeatureState>((set) => ({
  globalFeatures: null,
  getGlobalFeatureFlags: async () => {
    try {
      const request = await Axios.get<GlobalFeatureFlags>("/api/system/global-features");
      set({ globalFeatures: request.data });
    } catch (error) {
      console.error("Error fetching global feature flags:", error);
    }
  },
}));
